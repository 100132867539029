import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  private static currentUrl: string = undefined;
  private static previousUrl: string = undefined;

  constructor(private router: Router, private location: Location) {
    RouterService.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        RouterService.previousUrl = RouterService.currentUrl;
        RouterService.currentUrl = event.url;
      }
    });
  }

  public static get PreviousUrl(): string {
    return RouterService.previousUrl;
  }
}
