import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DOMAIN } from '../variables';
import { UserInfo } from '../model/user';
import { DevicesService } from '@main-page/service/devices.service';




@Injectable()
export class AuthService {

  public constructor(
      private http: HttpClient,
      private devicesService: DevicesService
  ) { }

  // TODO: remove any types
  public login(username: string, password: string): Observable<any> {
    return this.http.post<any>(DOMAIN + '/api/login_check', { username: username, password: password }).pipe(
        map((user: any) => {

          if (user && user.token) {
            localStorage.setItem('currentUser', JSON.stringify({ username, token: user.token }));
          }
        })
    );
  }

  public logout(): void {
    localStorage.removeItem('currentUser');
    this.devicesService.ngOnDestroy();
  }

  public getUserInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(DOMAIN + '/api/user/getInfo');
  }

  public resetPassword(email: string): Observable<any> {
    // TODO check with API
    return this.http.post(DOMAIN + '/api/user/forgotPassword', { email });
  }

  public get isLogged(): boolean {
    return localStorage.getItem('currentUser') !== null;
  }

}
