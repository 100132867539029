import { Injectable, OnDestroy } from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Device} from '@model/device';
import { HttpClient } from '@angular/common/http';
import { DOMAIN } from '@variables';


@Injectable({
  providedIn: 'root'
})
export class DevicesService implements OnDestroy {
    public devices: Device[];
    private subs: Subscription;
    public loading = true;

    constructor(private http: HttpClient) { 
        this.loadDevices();
    }

    public loadDevices(): void {
        this.devices = [];
        this.loading = true;
        this.subs = this.getAllDevices().subscribe((dev: Device[]) => {
            this.devices = dev;
            this.sort();
            this.loading = false;
        });
    }

    public getAllDevices(): Observable<Device[]> {
      return this.http.get<Device[]>( DOMAIN + '/api/device/devicesByUser');
    }

    public sort(): void {
        this.devices.sort((a: Device, b: Device) => this.getBigger(a, b));
    }

    public getBigger(a: Device, b: Device): number {
        if (a.preferred && !b.preferred) {
          return -1;
        }
        if (!a.preferred && b.preferred) {
          return 1;
        }
        return b.condition - a.condition;
    }

    public pin(id: number): void {
        let device = this.devices.find((d: Device) => d.id === id);
        this.devices = this.devices.filter((a: Device) => a.id !== id);
        device.preferred = !device.preferred;
        this.devices.push(device);
        this.sort();
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
    

}
