import { Component, OnInit } from '@angular/core';
import { RouterService } from '@main-page/service/router.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  private appTitle = 'IdaApp';

  public constructor(
    private router: RouterService
  ) { }

  public ngOnInit(): void {
  }

  public get title(): string {
    return this.appTitle;
  }
}

