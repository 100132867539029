import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {AuthService} from './service/auth.service';
import {ErrorInterceptor} from './service/error-interceptor.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtInterceptor} from './service/jwt-interceptor.service';
import {MatButtonModule, MatIconModule, MatToolbarModule, MAT_DATE_LOCALE} from '@angular/material';
import {MatPasswordStrengthModule} from '@angular-material-extensions/password-strength';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DeviceService } from './main-page/device-home-page/device.service';
import {ImgElectronDisplayService} from './service/img-electron-display.service';



const matImports = [
  MatIconModule,
  MatMomentDateModule,
  MatToolbarModule,
  MatPasswordStrengthModule.forRoot(),
  MatButtonModule,
  MatSnackBarModule
];

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ...matImports
  ],
  providers: [
    AuthService,
    DeviceService,
    ImgElectronDisplayService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
