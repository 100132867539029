import { enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
enableProdMode();
const locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'cs';
declare const require;
const translations = require(`raw-loader!./locale/messages.${locale}.xlf`).default;

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [
    {provide: TRANSLATIONS, useValue: translations},
    {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'},
    {provide: LOCALE_ID, useValue: locale}
  ]
})
  .catch(err => console.error(err));


