import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  Router,
  Route,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';

import { AuthService } from './auth.service';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  public constructor(
    private router: Router,
    private location: Location,
    private auth: AuthService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin(state.url);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  public canLoad(route: Route): boolean {
    return this.checkLogin(this.location.path());
  }

  public checkLogin(url: string): boolean {
    if (this.auth.isLogged) {
      return true;
    }

    this.router.navigate(['/login-page'], { queryParams: { returnUrl: url } });
    return false;
  }
}
