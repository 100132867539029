import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import {catchError, debounceTime} from 'rxjs/operators';

import { AuthService } from './auth.service';
import { I18nSelectPipe } from '@angular/common';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public language: string = localStorage.getItem('locale');
  public inviteMap: any = {cs: 'Byl jste odhlášen!', other: 'You have been logged out!'};
  public handled = false;

  public constructor(
    private route: Router,
    public snackBar: MatSnackBar,
    private location: Location,
    private auth: AuthService
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(debounceTime(10000), catchError((err: any) => {
      const mess: string = new I18nSelectPipe().transform(this.language, this.inviteMap);

      if (err.status === 406) {
        // incorrect current password when changing password

        this.route.navigate(['/main-page/settings/']);

      } else if (err.status === 401) {
        // auto logout if 401 response returned from api
        if (!this.handled) {
          this.handled = true;
          setTimeout(() => { this.handled = false; }, 4500);

          localStorage.removeItem('currentUser');
          this.route.navigate(['login-page'], { queryParams: { returnUrl: this.location.path() } });

          setTimeout(() => this.snackBar.open(mess, '', {
            duration: 2500
          }), 500);
        }

      } else {

        this.route.navigate(['not-found'], { queryParams: { error: err.status, returnUrl: this.location.path() } });
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }
}
