import { Injectable } from '@angular/core';
import { ELECTRON_BUILD } from '@variables';

@Injectable({
  providedIn: 'root'
})
export class ImgElectronDisplayService {

  constructor() { }

  public getRightImgUrl(URL: string): string {
    if (!ELECTRON_BUILD) {
      return URL;
    } else {
      return this.parse(URL);
    }
  }

  private parse(URL: string): string {
    return '../IdaApp/assets' + URL.split('assets')[1]; 
  }
}
