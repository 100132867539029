import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { DOMAIN } from '@variables';
import { ActivatedRoute, Router } from '@angular/router';
import { ImgElectronDisplayService } from '../service/img-electron-display.service';


@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.sass']
})
export class PageNotFoundComponent implements OnInit {

  private typeError: number;
  private wrongRouteError: string;

  public img = '/assets/logo_zelena-03.svg';

  public constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private imgService: ImgElectronDisplayService
  ) { }

  public ngOnInit(): void {
    this.typeError = this.route.snapshot.queryParams.error ? this.route.snapshot.queryParams.error : 404;

    if (this.type === 401) {
      this.router.navigate(['login-page'], {queryParams: {returnUrl: this.route.snapshot.queryParams.returnUrl}});
      return;
    }
    if (this.type === 403) {
      this.wrongRouteError = DOMAIN + this.location.path();
    }
    this.img = this.imgService.getRightImgUrl(this.img);
  }

  public redirect(): void {
    window.open('http://www.4dot.cz/', '_blank');
  }

  public get wrongRoute(): string {
    return this.wrongRouteError;
  }

  public get type(): number {
    return this.typeError;
  }

  public returnToMainPage(): void {
    this.router.navigate(['/main-page/']);
  }


}
