import { DOMAIN } from '@variables';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToggleDevice } from '@model/device';


@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private http: HttpClient) { }

  private deviceUtilComparisons: BehaviorSubject<any> = new BehaviorSubject(0);
  public currentDeviceUtilComparisons: Observable<any> = this.deviceUtilComparisons.asObservable();

  public setComparison(deviceId: number, deviceName: string): void {
    this.deviceUtilComparisons.next({id: deviceId, name: deviceName});
  }

  public setPreferredDevice(id: number): Observable<any> {
    return this.http.post<ToggleDevice>( DOMAIN + '/api/device/setPreferred', { device: id });
  }

  public unsetPreferred(id: number): Observable<any> {
    return this.http.post<ToggleDevice>( DOMAIN + '/api/device/unsetPreferred', { device: id });
  }

  public getPieUtilization(id: number, from: number, to: number): Observable<any> {
  return this.http.get<any>(DOMAIN + '/api/message/getUtilizationInPercents?device=' +
      id + '&timeFrom=' + from + '&timeTo=' + to + '&shifts=');
  }

}
